
html{
  scroll-behavior: smooth;
}

.container, .sppb-row-container{
  max-width: 1200px !important;
  width: 100% !important;
}

#sp-main-body{
  padding:0px;
}

body:not(.home){
  #sp-main-body {
    padding-bottom: 100px;

    #sp-component{
      padding-top: 45px;
    }

    #sp-left, #sp-right{
      > .sp-column{
        padding-top: 45px;
      }
    }

  }
}

body{
  #sp-component{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
  }
  &.expo-village{
    #sp-component{
      background-image: url("/resources/images/leone.png");
    }
  }
}


/* HEADER */
#sp-header{

  height: $header-height !important;


  .container-inner {
    padding-top: 0;

    @include media-breakpoint-up(lg) {
      padding-top: 18px;
    }
  }

  &.header-sticky{
    position: sticky;
    top:0px;
    width:100%;
  }

  #offcanvas-toggler{
    height: $header-height !important;
    line-height: $header-height !important;

    &.offcanvas-toggler-right{
      float: none !important;
      margin: 0 auto !important;
    }
  }

  .logo-desktop{
    img{
      max-width: 100%;
      max-width: 100%;
      height: 90px;
      margin-left: -25px;
      margin-top: -5px;
    }
  }

  .mod-languages{
    padding: 10px 0px;
  }

  nav{
    .sp-megamenu-parent{
      > li{
        > a{
          color:$text-color;
          line-height: 35px;
          font-size: 14px;
          font-weight: 700;
          position: relative;
          padding-top: 6px;

          &.virtual{
            padding-left: 20px;
            &:before{
              content: ' ';
              display: inline-block;
              width: 25px;
              height: 40px;
              background-position: left;
              background-image: var(--virtual-menu-image);
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              left:0px;
              margin-top: -10px;
            }
          }

          &:hover{
            color:$primary-color !important;
          }
          &:visited{
            color:$text-color;
          }
          small{
            display: block;
            color:$primary-color;
            font-size: 11px;
            margin-top: -12px;
          }
          &:after{display: none;}
        }
        .active{
          > a{}
        }

        &.badge-sm {
          > a {
            .sp-menu-badge {
              font-size: 11px;
            }
          }
        }

        &.badge-block {
          > a {
            .sp-menu-badge {
              line-height: 35px;
              display: block;
              font-weight: 400;
              color: $primary-color;
              background: transparent;
              padding: 0;
              text-transform: none;
              letter-spacing: 0;
              margin-top: -12px;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
      .sp-dropdown{
        .sp-dropdown-inner{
          background: $primary-color;
          .sp-menu-item{
            a{
              color: white;
              font-size:13px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: $screen-xs) {
    height: 150px;
  }
}



/* PAGE CONTENT */

.article-details .article-header h1, .article-details .article-header h2 {
  font-size: 2rem;
  color: $primary-color;
  font-weight: 800;
}


.page-content{
  .sppb-section{
    &.border-top-black{
      &:before{
        content:' ';
        width:auto;
        height:1px;
        display:block;
        border-top:1px solid $border-color;
        margin-left: 15px;
        margin-right: 15px;
        /* margin-top: -40px; */
        transform: translateY(-40px);
      }

    }
    &.border-bottom-black{
      &:before {
        content: ' ';
        width: 100%;
        height: 1px;
        display: block;
        border-bottom:1px solid $border-color;
      }
    }

    &.justify-content-center{
      .sppb-row{
        justify-content: center;
      }
    }
  }
  .sppb-section-title{
    margin: 0px;
    margin-bottom: 0px !important;

    h3{
      color: $primary-color;
      text-transform: uppercase;
      padding: 5px 0px;
      margin: 0px !important;
      font-size: 22px !important;
      font-weight: 400;
      //mobile
      @include mq('tablet') {
        font-size:20px;
      }
    }

    p{
      font-size: 20px;
      line-height: 24px;
    }
  }


  h2.sppb-addon-title{
    font-size:28px !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    color: $primary-color;
  }

  h3.sppb-title-heading{
    font-size:24px !important;
    font-weight: 700 !important;
    line-height: 1.2 !important;
    color: $primary-color;
    padding-bottom: 20px;
  }


  h5.sppb-addon-title{
    font-size:20px;
    font-weight: 400;
  }
}

/* TABLE */

.tabella_classifiche{
  overflow-x:auto;
  margin: 20px 0px;
  table{
    td{
      border:1px solid #efefef;
      padding:10px;
      text-align: left !important;
      vertical-align: center;
    }
    tbody{
      tr:first-child{
        td[rowspan="1"]{
          background: $secondary-color;
          border-color: $secondary-color;
          color:white;
        }
      }
    }
  }
}


/* FOOTER */
#sp-footer {

  background-color: white;
  border: none;
  color: $text-color;
  a, a:hover, a:active, a:visited{
    color: $text-color;
  }

  &.background-primary{
    background-color: $primary-color;
    color: white;
    a, a:hover, a:active, a:visited{
      color: white;
    }
  }

  &.background-secondary{
    background-color: $secondary-color;
    color: white;
    a, a:hover, a:active, a:visited{
      color: white;
    }
  }

  padding: 20px 0px 20px 0px;
  .container-inner{
    border:none;
  }

}



/* OFFCANVAS */

.offcanvas-menu{
  background-color: $primary-color;
  color:white;
  overflow-y:auto;
}

.offcanvas-active {
  .burger-icon {
    > span {
      background: white;

      &:nth-child(3) {
        transform: translate(0, -6px) rotate(45deg);
      }
    }
  }
}

.sticky{
  position: sticky;
}