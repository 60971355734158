.mod-masthead {
  position: relative;

  .image-masthead{
    img{ width:100%;}
  }

  .logo-masthead{
    width:100%;
    z-index: 10;
    text-align: center;
    padding-top:180px;

    div{
      background-color:white;
      width:265px;
      height: 265px;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
      margin: 0 auto;
      text-align: center;
      img{
        max-width: 90%;
        margin: 0 auto;
      }
    }

    p{
      padding: 40px;
      font-size: 90%;
      font-weight: 200;
      color:#999;
      margin: 0px;
    }
  }

  .image-masthead{

    position: absolute;
    top: 0px;
    left:0px;
    z-index: -1;
    width:100%;


    &:before{
      display: block;
      content: ' ';
      background-color: #212a55;
      position: absolute;
      width: 20%;
      height: 80%;
      bottom: 0;
      left: 0;
      z-index: 10;
      /*
      clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
       */
      background: url("/resources/images/vela_blu.png");
     background-repeat: no-repeat;
     background-size: contain;
     background-position: bottom left;
    }
    &:after{
      display: block;
      content: ' ';
      position: absolute;
      width: 35%;
      height: 50%;
      top: 0;
      right: 0;
      z-index: 10;
      /*
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
      background: #dda601;
      background: -moz-linear-gradient(top,  #dda601 0%, #eb6223 100%);
      background: -webkit-linear-gradient(top,  #dda601 0%,#eb6223 100%);
      background: linear-gradient(to bottom,  #dda601 0%,#eb6223 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dda601', endColorstr='#eb6223',GradientType=0 );
      */

     background: url("/resources/images/vela_arancione.png");
     background-repeat: no-repeat;
     background-size: contain;
     background-position: top right;

    }
  }

  @media only screen and (max-width: $screen-sm) {

    .logo-masthead{
      padding-top: 60px;
      div{
        width:140px;
        height:140px;
      }
    }

  }


}


