.custom{
  &.logos{
    display: flex;
    img{
      flex:1;
    }
    clear: left;
    @media only screen and (min-width: $screen-sm) {
      width:60%;
      margin:0 20%;
    }
  }

  &.copyright{
    font-size:14px;
    //mobile
   	 @include mq('tablet') {
   	   font-size:12px;
   	 }
  }

  &.menu_sidebar{
    a{
      display: inline-block;
      padding: 8px 0px;
      font-family: 'Montserrat', Arial, sans-serif !important;
      font-weight: 700;
      border-bottom: 1px solid #efefef !important;
      width: 100%;
      text-decoration: none;
      &:last-child{
        border: none;
      }
    }
  }
}