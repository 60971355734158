.mod-countdown-default {
  text-align: center;
  padding: 40px 0;

  #timer {

    position: relative;

    .numbers {
      display: inline-block;
      text-align: center;
      padding: 0 2%;
      position: relative;
      margin: 0px;

      font-size:50px;
      line-height:50px;
      font-weight:400;

      //tablet
      @include mq('tablet-wide') {
        font-size:50px;
        line-height:50px;
      }

      //mobile
      @include mq('tablet') {
        font-size:35px;
        line-height:35px;
      }

      span{
        position: absolute;
        width: 100%;
        text-align: center;
        top:0px;
        left:0px;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        margin-top: 60px;

        //tablet
        @include mq('tablet-wide') {
          font-size: 12px;
          line-height: 12px;
        }
        //mobile
        @include mq('tablet') {
          font-size: 10px;
          line-height: 10px;
          margin-top: 45px;
        }
      }

    }

     .sep{
        display: inline-block;
        background-color: $primary-color;
        width: 14px;
        height: 14px;
        margin-left: -9px;
        border-radius: 100%;
        top: 50%;
        transform: translate(0%,-50%);
        padding: 0px;
        position: absolute;

    }
  }


  .pretext, .posttext {
    text-align: center;
    padding: 10px 0px;
	color:$text-color;
  }

  .pretext{
    font-size:20px;
    line-height:28px;
    font-weight: 200;

    //mobile
    @include mq('tablet') {
      font-size:17px;
      line-height:25px;
      font-weight: 200;
    }

    //tablet
    @include mq('tablet-wide') {
      font-size:20px;
      line-height:28px;
      font-weight: 200;
    }

  }

}

@media only screen and (max-width: 980px) {

  .mod-countdown-default{
    #timer{
      .sep{
        width: 10px;
        height: 10px;
        margin-left: -7px;
      }
    }
  }

}

@media only screen and (max-width: $screen-xs) {
  .mod-countdown-default{
    #timer{
      .numbers{
        min-width: auto;
      }
    }
  }
}



/* NUMBERS TEMPLATE */

.mod-countdown-numbers {
  .countdown {
    margin: 10px 0;
  }
  .c1 {
    width: 60%;
    float: left;
  }
  .c2 {
    width: 40%;
    float: left;
  }
  .img-sponsor {
    position: absolute;
    bottom: 0;
    max-width: 130px !important;
    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }
  }
}