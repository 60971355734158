.sppb-addon-image-layouts{
  &.focus{
    padding-bottom: 60px;
    .sppb-addon-image-layout-content{
      .sppb-btn{
        position: absolute;
        left:50%;
        transform: translateX(-50%);
      }
    }

    .sppb-image-layout-title{
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      //mobile
      @include mq('tablet') {
    	   font-size:15px;
    	 }
    }

    .sppb-addon-image-layout-image{
     position: relative;
      .sppb-img-figcaption{
        position: absolute;
        z-index: 1;
        width:80%;
        color: white;
        text-align: center;
        margin: 0 10%;
        top:50%;
        transform: translateY(-50%);
        overflow: hidden;
        font-weight: 200;
        font-size: 24px;
        line-height: 1.2;
        text-shadow: 0px 0px 3px rgba(0,0,0,0.25);
      }
    }
  }
}

.sppb-panel-title{
  font-weight: 400;
  font-size: 14px;
  //mobile
  @include mq('tablet') {
	   font-size:13px;
	 }
}


.sppb-addon-table-main{
  td{
    padding: 0px;
  }
  tbody{
    tr{
      background: none;
    }
  }
}


.sppb-addon{

  &.sppb-addon-overlay-image{
    .overlay-image-title{
      &:before{
        border: none !important;
      }
    }
  }

  &.sppb-addon-single-image {
    &.grayscale{
      img{
        filter: grayscale(100%);
        :hover{
          filter: grayscale(0%);
        }
      }
    }
  }
}