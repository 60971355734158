#sp-left .sp-module, #sp-right .sp-module {
	margin: 0px;
	border: none;
	padding: 0px;
	border-radius: 0px;
	margin-bottom: 20px;

	h3.sp-module-title{
		border:0px;
		color:$primary-color;
		padding-bottom: 0px;
		font-size: 20px;
		font-weight: 400;
	}
}

/* MENU SIDEBAR */

.menu_sidebar{
	padding-bottom: 40px;
	a{
		color: $text-color;
		text-decoration: none;
		font-size: 14px;
		font-weight: 200;
		&:hover,&:focus{
			color:$primary-color;
		}
	}
}

.page-content{
	.menu_sidebar{
		padding-bottom: 0px;
		padding-top: 20px;

		a{
			text-decoration: underline;
			color:$primary-color;
		}
	}
}


/* MENU OFFCANVAS */

.offcanvas-menu {

	.logo {
		display: none;
		visibility: hidden;
	}

	.close-offcanvas{
		background: none !important;
		font-size: 20px;

		.burger-icon {
			> span {
				background-color: white !important;
			}
		}
	}

	.offcanvas-inner {
		margin-top: -40px;


		.sp-module {
			/* select languages */
			.mod-languages {
				padding-bottom: 20px;
				margin-top: -5px;
				clear: both;

				ul.lang-inline {
					display: block;

					> li {
						display: inline-block;
						margin-right: 8px;
						float: left;

						&.lang-active {
							background-color: transparent;
						}

						img {
							width: 24px;
							height: 16px;
						}
					}
				}
			}


			/* menu */
			ul.menu {
				> li {
					border-bottom: 2px solid white;
					padding: 20px 0px 10px 0px;

					> a {
						color: white;
						font-weight: 800;
						font-size: 20px;
						position: relative;
						padding: 0.125rem 0;
						opacity: 1;

						span {
							color: white !important;
							font-size: 12px;
							display: block;
							max-width: 80%;
							line-height: 22px;
							padding: 0.125rem 0;
						}


						&.virtual {
							padding-left: 20px;

							&:before {
								content: ' ';
								display: inline-block;
								width: 25px;
								height: 40px;
								background-position: left;
								background-image: url("/resources/images/virtual-race-white.png");
								background-size: contain;
								background-repeat: no-repeat;
								position: absolute;
								left: 0px;
								margin-top: -10px;
							}
						}

					}

					> ul {
						margin: 8px 0px 0px 0px !important;
						border-top: 2px solid white;
						padding-top: 6px;
						padding-bottom: 0;

						> li {
							padding: 6px 0px;

							> a {
								color: white;
							}
						}
					}

					.menu-toggler {
						color: white !important;
						font-size: 20px;

						&:after {
							content: '\f107';
						}
					}

					&.menu-parent-open {
						.menu-toggler {
							&:after {
								content: '\f106';
							}
						}
					}
				}
			}
		}
	}
}


/* MENU FOOTER */
#sp-footer{
	.sp-module{
		&.menu-inline-big{
			ul.menu{
				margin:0px;
				padding: 20px 0px;
				li{
					display: inline-block;
					a{
						color: $text-color !important;
						display: block;
						font-size: 16px;
						padding: 3px 20px;

						//mobile
						@include mq('tablet') {
							font-size:12px;
						}
					}
				}
			}
		}
	}
}
