.rsform {

  .form-group{
    .control-label{

      font-size:90%;

      .formRequired{
        font-weight: normal;
      }
    }
    .form-control{
      border-radius: 0px;
      background-color: #cccccc;
      border: 1px solid white;
      padding: 10px;
      color: #333;
      height: auto;
      &:focus{
        background-color: white;
        border: 1px solid #cccccc;
      }
    }
    .form-check {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .control-label[data-original-title="hidden"]{
      display: none;
    }

  }

  .formError {
      color: red;
      font-weight: bold;
      font-size: 12px;
  }

  @media only screen and (max-width: $screen-sm) {
    .form-row{
      div[class^="col-"]{
        width:100%;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0px;
        padding: 8px 0px;
      }
    }
  }


}




