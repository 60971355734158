$header-height: 120px !default;

/* colours */
$primary-color: var(--primary-color) !default;
$secondary-color: var(--secondary-color) !default;
$text-color: var(--text-color) !default;
$border-color: var(--border-color) !default;
$light-grey-color: #f2f2f2 !default;


// font family
$font-family-light : 'Helvetica Neue LT W05_45 Light' !default;
$font-family-light-italic : 'Helvetica Neue LT W05_46 Lt It' !default;
$font-family-regular : 'Helvetica Neue LT W05_55 Roman' !default;
$font-family-regular-italic : 'Helvetica Neue LT W05_55 Roman' !default;
$font-family-bold: 'Helvetica Neue LT W01_75 Bold' !default;
$font-family-bold-italic: 'Helvetica Neue LT W05_76 Bd It' !default;

// screen sizes
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

// images
/*
:root {
  --virtual-menu-image: url("/resources/images/virtual-race-orange.png");
}*/
