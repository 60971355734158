.mod-articlescategories{

  a{
    line-height: 1;
    padding: 8px 0px;
    color: inherit;
    display: inline-block;
  }

}

.mod-articles-categories-collapse{


 a[data-level="0"]{
    font-weight: bold;
  }

  ins{
    float:right;
    text-decoration: none;
    font-size:120%;
    &[aria-expanded="false"]{
      &:before{content:"+";}
    }
    &[aria-expanded="true"]{
      &:before{content:"-";}
    }
  }

}