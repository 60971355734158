.mod_charity {

    border-style: solid;
    border-color: var(--primary-color) !important;

  .bottom{
    background-color: $light-grey-color;
    border-color: var(--primary-color) !important;
  }

  .ticker_projects {

    ul {
      margin: 0px;
      padding: 0px;
      list-style: none;
      li{
        font-size: 110%;
        line-height: 1.2;
        min-height: 90px;
      }
    }

    .amount {
      font-weight: bold;
      background-color: white;
      padding: 8px 16px;
      border-radius:30px;
      display: inline-block;
    }

  }

}