


.mod-counter{
  font-size:24px;
  line-height: 1;
  background-color: $light-grey-color;

  .title{
    line-height: 1;
    padding-bottom:35px;
    font-size:25px;
    font-weight:200;

    //mobile
    @include mq('tablet') {
      font-size: 25px;
    }

  }

  .total_subscribers{
    color: $primary-color;
    line-height: 1;
    font-weight: 700;
    font-size:30px;

    //mobile
    @include mq('tablet') {
      font-size: 25px;
    }

  }
  .total_changerate{
    font-weight: bold;
    padding-top:25px;
  }

  .text_subscribers, .text_changerate{
    color:#58595b;
    font-size: 20px;
    font-weight: 200;
    @include mq('tablet') {
      font-size: 17px;
    }
  }

  a{
    display: block;
    width:100%;
    height: 100%;
    padding: 25px;
    color: $text-color;

    &:hover, &:focus{
      background-color: $primary-color;
      *{color: white;}
    }
  }

}