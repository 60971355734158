.btn-scroll-to-top{
	color:$primary-color !important;
	margin-top: -20px;
	cursor: pointer;
	display: inline-block;
	z-index: 10;
	position: absolute;
	right: 0px;
	font-size:14px;
	font-weight: 400;

	@include mq('tablet') {
		font-size:13px;
	}

	&:active,&:focus,&:hover,&:visited{
		color:$primary-color;
	}
	i{
		font-weight: bold;
		font-size: 18px;
	}
}