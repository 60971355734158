.sppb-btn, .btn{

	background-color: white;
	padding: 8px 22px;
	font-size: 16px;
	font-weight:600;

	&.sppb-btn-default, &.btn-default{

		background-color: white;
		border:1px solid white;
		color:$primary-color;

		&:hover,&:active{
			background-color: white;
			border:1px solid white;
			color:$primary-color;
		}
	}

	&.sppb-btn-primary, &.btn-primary{

		background-color: $primary-color;
		border:1px solid $primary-color;
		color: white;


		&:hover,&:active{
			background-color: $primary-color;
			border:1px solid $primary-color;
			color: white;
		}
	}

	&.sppb-btn-secondary, &.btn-secondary{

		background-color: $secondary-color;
		border:1px solid $secondary-color;
		color: white;

		&:hover,&:active{
			background-color: $secondary-color;
			border:1px solid $secondary-color;
			color: white;
		}
	}


	&.sppb-btn-rounded, &.btn-rounded{
		border-radius:50px;
	}

	&.sppb-btn-xlg, &.btn-xlg{
		font-size: 22px;
		font-weight: 600;
	}


	//mobile
	 @include mq('tablet') {
	   font-size:13px;
	 }
}


