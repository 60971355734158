
.ff-stream{
  padding:0px !important;
  min-height: auto !important;
  .ff-loadmore-wrapper{
    .ff-btn{
      margin:20px 0px;
    }
  }
}
