#sp-header{
  .mod-languages{
    padding: 20px 0px;
  }
}


.mod-languages {
  .dropdown-toggle{
    padding:0px;
    .title{
      display: none;
    }
    img{
      float:left;
      width:32px;
    }
    &:after{
      display: none;
    }
    .fa{
      color: $primary-color;
      margin-top: -5px;
      font-size: 22px;
      font-weight: bold;
      float: right;
      margin-left: 10px;
      margin-top: 0px;
    }
  }
  .dropdown-menu{
    border-radius:0px;
    box-shadow:0px 0px 6px rgba(0,0,0,.15);
    min-width: 40px;
    border:none;
    li{
      margin: 0;
      padding: 10px 11px !important;
      a {
        padding: 0;

        img {
          width: 32px;
        }

        .title {
          display: none;
        }
      }
    }
  }
}